<template>
  <div class="m-header">
    <div class="header">
      <el-icon @click="showMenu = true" v-if="!showMenu"><Operation /></el-icon>
      <el-icon @click="showMenu = false" v-else><CloseBold /></el-icon>
      <p>小胡的个人博客</p>
      <el-icon @click="show = true"><Search /></el-icon>
    </div>
    <div class="menu" v-show="showMenu">
      <div
        v-for="item in menu"
        :key="item.id"
        class="menu-item"
        @click="
          () => {
            $router.push(item.link);
            showMenu = false;
          }
        "
        :class="path === item.link && 'current'"
      >
        <img :src="item.icon" style="margin-right: 4px" />
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
  <el-dialog v-model="show" :show-close="false" width="90%">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <el-input v-model="search" placeholder="输入关键字搜索" :suffix-icon="Search">
          <!-- <template #prepend
            ><el-icon><Search /></el-icon
          ></template> -->
        </el-input>
        <div style="margin-left: 10px; cursor: pointer" @click="close">
          <el-icon color="#555" size="20"><CircleCloseFilled /></el-icon>
        </div>
      </div>
    </template>
    <div class="dialog-content">
      <div class="null" v-if="list == undefined">
        <div>
          <el-icon size="80" color="#ccc"><Search /></el-icon>
        </div>
      </div>
      <div class="list" v-else>
        <p class="result">找到{{ list.length }}个结果</p>
        <p class="title" v-for="(item, index) in list" :key="index">
          <span class="dot"></span>
          <span
            class="t"
            @click="
              () => {
                (show = false),
                  $router.push({ path: '/article', query: { id: item.id } });
              }
            "
            >{{ item.title }}</span
          >
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as Vue from "vue";
import api from "../assets/request/api";
import { useRoute } from "vue-router";
import { Search } from '@element-plus/icons-vue'
export default {
  setup() {
    const route = useRoute();
    const show = Vue.ref(false);
    const showMenu = Vue.ref(false);
    const search = Vue.ref(undefined);
    const list = Vue.ref(undefined);
    const page = Vue.reactive({
      pageNo: 1,
      pageSize: 10,
      total: 0,
      pages: 0,
    });
    const getList = (page) => {
      api.getBlogList(page).then((res) => {
        if (res.data.code === 20000) {
          list.value = res.data.data.records;
        }
      });
    };

    const menu = [
      {
        name: "首页",
        icon: require("./img/sy.png"),
        link: "/",
      },
      {
        name: "归档",
        icon: require("./img/gd.png"),
        link: "/archives",
      },
      {
        name: "分类",
        icon: require("./img/fl.png"),
        link: "/sort",
      },
    ];
    Vue.watch(
      () => search.value,
      () => {
        page.name = search.value;
        page.pageNo = 1;
        getList(page);
      }
    );
    const path = Vue.ref("/");
    Vue.watch(
      () => route.path,
      () => {
        path.value = route.path;
      }
    );

    return {
      path,
      show,
      search,
      list,
      showMenu,
      menu,
      Search
    };
  },
};
</script>

<style lang="less" scoped>
.m-header {
  .header {
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 0 16px;
    font-size: 22px;
    color: #222;
  }
  .menu {
    background-color: #f5f5f5;
    padding: 16px;
    .menu-item {
      color: #555;
      font-size: 13px;
      display: flex;
      align-items: center;
      height: 26px;
    }
    .current {
      background-color: #ddd;
    }
  }
}
.my-header {
  display: flex;
  align-items: center;
}
.dialog-content {
  height: 500px;
  .null {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list {
    color: #555;
    font-size: 16px;
    .result {
      border-bottom: 2px dashed #ccc;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    .title {
      margin-bottom: 20px;
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: #999;
        margin-right: 10px;
      }
      .t {
        display: inline-block;
        padding-bottom: 6px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}
</style>
