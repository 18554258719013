<template>
  <div class="header">
    <div class="main-box">
      <div class="left">
        <div class="border"></div>
        <a link="">小胡的个人博客</a>
        <div class="border"></div>
      </div>
      <div class="menu">
        <div
          v-for="item in menu"
          :key="item.name"
          class="item"
          @click="item.action ? item.action() : $router.push(item.link)"
          :class="path === item.link && 'current'"
        >
          <img :src="item.icon" style="margin-right: 4px" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="show" :show-close="false" width="60%">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <el-input v-model="search" placeholder="输入关键字搜索" clearable :suffix-icon="Search">
          <!-- <template #prepend
            ><el-icon><Search /></el-icon
          ></template> -->
        </el-input>
        <div style="margin-left: 10px; cursor: pointer" @click="close">
          <el-icon color="#555" size="20"><CircleCloseFilled /></el-icon>
        </div>
      </div>
    </template>
    <div class="dialog-content">
      <div class="null" v-if="list == undefined">
        <div>
          <el-icon size="80" color="#ccc"><Search /></el-icon>
        </div>
      </div>
      <div class="list" v-else>
        <p class="result">找到{{ list.length }}个结果</p>
        <p class="title" v-for="(item, index) in list" :key="index">
          <span class="dot"></span>
          <span
            class="t"
            @click="() => {show = false,$router.push({path:'/article',query:{id:item.id}})}"
            >{{ item.title }}</span
          >
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as Vue from "vue";
import { useRoute } from "vue-router";
import api from "../assets/request/api";
import { Search } from '@element-plus/icons-vue'
export default {
  setup() {
    const route = useRoute();
    const show = Vue.ref(false);
    const search = Vue.ref(undefined);
    const menu = [
      {
        name: "首页",
        icon: require("./img/sy.png"),
        link: "/",
      },
      {
        name: "归档",
        icon: require("./img/gd.png"),
        link: "/archives",
      },
      {
        name: "分类",
        icon: require("./img/fl.png"),
        link: "/sort",
      },
      {
        name: "搜索",
        icon: require("./img/ss.png"),
        action: () => {
          show.value = true;
        },
      },
    ];
    const path = Vue.ref("/");
    Vue.watch(
      () => route.path,
      () => {
        path.value = route.path;
      }
    );

    const list = Vue.ref(undefined);
    const page = Vue.reactive({
      pageNo: 1,
      pageSize: 10,
      total: 0,
      pages: 0,
    });
    const getList = (page) => {
      api.getBlogList(page).then((res) => {
        if (res.data.code === 20000) {
          list.value = res.data.data.records;
        }
      });
    };

    Vue.watch(
      () => search.value,
      () => {
        if(search.value){
          page.name = search.value;
        page.pageNo = 1;
        getList(page);
        }else {
          list.value = undefined
        }
        
      }
    );

    return {
      menu,
      path,
      show,
      search,
      list,
      Search
    };
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 20px 0;
  background-color: #f5f5f5;
  .main-box {
    display: flex;
    align-items: center;
    .left {
      font-size: 22px;
      color: #222;

      .border {
        width: 120px;
        height: 2px;
        background: #222;
        margin: 0 auto;
      }
    }
    .menu {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #555;
      font-size: 13px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        margin: 0 4px;
        cursor: pointer;
        border-radius: 2px;
      }
      .item:hover {
        background-color: #ddd;
      }
      .current {
        background-color: #ddd;
      }
    }
  }
}
.my-header {
  display: flex;
  align-items: center;
}
.dialog-content {
  height: 60vh;
  .null {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list {
    color: #555;
    font-size: 16px;
    .result {
      border-bottom: 2px dashed #ccc;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    .title {
        margin-bottom: 20px;
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: #999;
        margin-right: 10px;
      }
      .t {
        display: inline-block;
        padding-bottom: 6px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}
</style>
