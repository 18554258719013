import { createRouter, createWebHashHistory } from 'vue-router'
import List from '../views/List.vue'

const routes = [
  {
    path: '/',
    name: 'list',
    component: List
  },
  {
    path:'/sort',
    name:'sort',
    component:() => import('../views/Sort.vue')
  },
  {
    path:'/archives',
    name:'archives',
    component:() => import('../views/Archives.vue')
  },
  {
    path: '/article',
    name: 'article',
    component:() => import('../views/article.vue')
  },
  {
    path:'/search',
    name:'search',
    component:() => import('../views/Search.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router