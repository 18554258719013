<template>
  <div class="footer">
    <div class="main-box df">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602001967"
        style="display: flex; align-items: center;margin-right: 20px;"
      >
        <img src="./img/1.png" />
        <p>川公网安备 51010602001967号</p>
      </a>
      <a target="_blank" href="https://beian.miit.gov.cn">
        <p>蜀ICP备2023001525号-2</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.footer {
  background: #f5f5f5;
  color: #555;
  padding: 10px 0;
  font-size: 14px;
  .df{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
