<template>
  <div class="list">
    <div class="main-box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <p class="main-title" style="cursor: pointer;text-align: center;" @click="$router.push({ path: '/article', query: { id: item.id } })">{{ item.title }}</p>
        <div class="tips label-font">
          <p style="display: flex; align-items: center;flex-wrap: wrap;">
            <img src="./img/fb.png" alt="" />
            <span>发表于</span>
            <span>{{ dayjs(item.createTime).format('YYYY-MM-DD')  }}</span>
          </p>
          <!-- <p style="display: flex; align-items: center">
            <img src="./img/gx.png" alt="" />
            <span>更新于</span>
            <span>{{ item.createTime }}</span>
          </p> -->
          <p style="display: flex; align-items: center">
            <img src="./img/fl.png" alt="" />
            <span>分类于</span>
            <span class="link">{{ item.cateName }}</span>
          </p>
        </div>
        <p class="content">
          {{ item.content }}
        </p>
        <div
          class="more"
          @click="$router.push({ path: '/article', query: { id: item.id } })"
        >
          阅读全文&gt;
        </div>
      </div>
      <div class="page">
        <p class="left" v-show="page.pageNo != 1" @click="page.pageNo--">
          &lt;
        </p>
        <p
          v-for="(item, index) in page.pages"
          :key="index"
          :class="item === page.pageNo && 'current'"
          @click="page.pageNo = item"
        >
          {{ item }}
        </p>
        <p
          class="right"
          v-show="page.pageNo != page.pages"
          @click="page.pageNo++"
        >
          &gt;
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import api from "../assets/request/api";
import dayjs from 'dayjs'
export default {
  setup() {
    const list = Vue.ref([]);
    const page = Vue.reactive({
      pageNo: 1,
      pageSize: 10,
      total: 0,
      pages: 0,
    });
    let reg = /[\u4e00-\u9fa5]|[\（\）\《\》\——\；\，\。\“\”\<\>\！]/g;
    const getList = (page) => {
      api.getBlogList(page).then((res) => {
        if (res.data.code === 20000) {
          page.total = res.data.data.total;
          page.pages = res.data.data.pages;
          res.data.data.records.map((item) => {
            item.content = item.content.match(reg).join('').slice(0,180)+ '...'
          });
          list.value = res.data.data.records;
        }
      });
    };
    getList(page);
    Vue.watch(
      () => page.pageNo,
      () => {
        getList(page);
      }
    );

    return {
      page,
      list,
      dayjs
    };
  },
};
</script>

<style lang="less" scoped>
.item {
  padding-top: 80px;
  .tips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 10px 0 16px;
    p {
      border-left: 1px solid #333;
      padding: 0 6px;
      &:nth-of-type(1) {
        border-left: none;
      }
    }
    span {
      display: inline-block;
      margin: 0 4px;
      &:nth-of-type(2) {
        text-decoration: underline;
      }
    }
    .link {
      cursor: pointer;
      &:hover {
        color: #555;
      }
    }
  }
  .content{
    color: #555;
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 30px;
  }
  .more {
    color: #555;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}
.page {
  border-top: 1px solid #eee;
  margin: 120px 0;
  text-align: left;
  display: flex;
  p {
    padding: 4px 8px;
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      background-color: #ddd;
      color: #fff;
    }
  }
  .current {
    background-color: #ddd;
    color: #fff;
  }
}
</style>
