<template>
  <template v-if="type == 'pc'">
    <Header></Header>
    <div class="router-view">
      <router-view />
    </div>

    <Footer />
  </template>
  <template v-else>
    <HeaderM></HeaderM>
    <div class="router-view">
      <router-view />
    </div>

    <FooterM />
  </template>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import HeaderM from "./components/HeaderM.vue";
import FooterM from "./components/FooterM.vue";
import * as Vue from "vue";
export default {
  components: {
    Header,
    Footer,
    HeaderM,
    FooterM,
  },
  setup() {
    const type = Vue.ref("pc");
    const heightPx = Vue.ref('60vw')
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      type.value = "mo";
      heightPx.value = '90vw'
    } else {
      type.value = "pc";
      heightPx.value = '50vw'
    }
    return {
      type,
      heightPx
    };
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
}
.main-box {
  width: v-bind(heightPx);
  margin: 0 auto;
}

.main-title {
  font-size: 27px;
  color: #555;
}
.label-font {
  font-size: 14px;
  color: #999;
}
a {
  text-decoration: none;
  color: #222;
}
.router-view {
  min-height: calc(100vh - 74px - 40px);
}
</style>
