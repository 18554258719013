import axios from "axios";
const baseURL = "https://www.yhu-ocean.top";
const instance = axios.create({
  baseURL,
  timeout: 30000,
});
export default {
  getBlogList(params) {
    return instance.get(`/blog/list`, { params });
  },
  getBlogDetail(id) {
    return instance.get(`/blog/article/${id}`);
  },
  getBlogCate(id, params) {
    return instance.get(`/blog/cate/${id}`, { params });
  },
  getCategoryList() {
    return instance.get(`/blog/category/list`);
  },
  getCount(){
    return instance.get(`/blog/count`)
  },
  getArchives() {
    return instance.get(`/blog/archives`)
  }
  

};